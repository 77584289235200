import React from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";

const allBlogs = [
  {
    id: 1,
    title: "Hello World.",
    image: "images/works/content-writing.png",
    filesource: "../../blogs/hello-world-17-01-15.md",
    date: "17 Jauary, 2015",
    author: "Carter",
    category: "Thoughts",
  },
  {
    id: 2,
    title: "I Failed My First Ludum Dare.",
    image: "images/works/content-writing.png",
    filesource: "../../blogs/i-failed-blog-19-01-15.md",
    date: "19 Jauary, 2015",
    author: "Carter",
    category: "Thoughts",
  },
  {
    id: 3,
    title: "Constraints",
    image: "images/works/content-writing.png",
    filesource: "../../blogs/constraints-blog-26-01-17.md",
    date: "26 Jauary, 2017",
    author: "Carter",
    category: "Game Design",
  },
  {
    id: 4,
    title: "Design Decisions",
    image: "images/works/GameplayTest.gif",
    filesource: "../../blogs/design-decisions-blog-28-01-17.md",
    date: "28 Jauary, 2017",
    author: "Carter",
    category: "Game Design",
  },
  {
    id: 5,
    title: "Bomber's Run",
    image: "images/works/Gameplay2.gif",
    filesource: "../../blogs/brun-blog-17-02-17.md",
    date: "17 February, 2017",
    author: "Carter",
    category: "Games",
  },
  {
    id: 6,
    title: "Bomber's Run Released!",
    image: "images/works/bbbanner_blog.jpeg",
    filesource: "../../blogs/brun-release-blog-23-02-17.md",
    date: "23 February, 2017",
    author: "Carter",
    category: "Games",
  },
  {
    id: 7,
    title: "OURA",
    image: "images/works/OURA_Logo_blog.png",
    filesource: "../../blogs/oura-blog-04-11-18.md",
    date: "04 November, 2018",
    author: "Carter",
    category: "Web",
  },
];

function Blogs() {
  const limit = 3;
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="Latest Posts" />
        <div className="row blog-wrapper">
          {Array.from(allBlogs)
            .reverse()
            .slice(0, limit)
            .map((blogItem) => (
              <div className="col-md-4" key={blogItem.id}>
                <Blog blogData={blogItem} />
              </div>
            ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blog" className="btn btn-default">
            Show all blogs
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
