import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Acamedic Degree",
    years: "2000 - 2004",
    content:
      "BSc - Honors Computer Science with Software Engineering Specialization.",
  },
  
];

const experienceData = [
  {
    id: 1,
    title: "Senior Programmer Analyst at Western University",
    years: "2019 - Present",
    content:
      "Development and maintenance of enterprise-level applications. Improved internal development practices and tools that benefit the application development team.",
  },
  {
    id: 2,
    title: "Software Developer - Start.ca",
    years: "2018 - 2019",
    content:
      "Assisted on company projects and created solutions relating to architecture, implementation, testing, and maintenance.",
  },
  {
    id: 3,
    title: "Programmer Analyst - Western University",
    years: "2015 - 2018",
    content:
      "Development and maintenance of enterprise-level applications. Improving internal development practices and tools that benefit the application development team.",
  },
  {
    id: 4,
    title: "Cofounder and Developer - Halfbot.com",
    years: "2010 - 2016",
    content:
      "Lead developer on all Halfbot projects.",
  },
  {
    id: 5,
    title: "Web / Mobile Application Developer - Western University",
    years: "2011 - 2015",
    content:
      "Researched, designed, and developed mobile applications to improve staff and student experience on mobile platforms.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
    
          <div className="col-md-12">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="row">
          <div className="col-md-12 mt-2">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <a target="_blank" href="https://www.linkedin.com/in/melvin-samuel-9b35873" className="btn btn-default" target="_blank">
            Go to my Linkedin
          </a>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
