import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Carter",
  avatarImage: "/images/avatar-2.svg",
  content:
    "My name is Carter. I am a web and indie game developer. I am currently employed as a full-time software developer at a University in London, Ontario. In a previous life, I was 50% of the now-defunct Halfbot. Please check out my Blog or Projects page to see what I am currently working on..",
};

const progressData = [
  {
    id: 1,
    title: "Playdate Game Development Project",
    percantage: 5,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Web Development Project",
    percantage: 75,
    progressColor: "#FF4C60",
  },
  /*
  {
    id: 3,
    title: "Game Design / Development",
    percantage: 80,
    progressColor: "#6C6CE5",
  },*/
];

const counterData = [
  /*{
    id: 1,
    title: "Games Released",
    count: 6,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Cup of coffee",
    count: 29001,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Satisfied clients",
    count: 427,
    icon: "icon-people",
  },
  {
    id: 4,
    title: "Nominees winner",
    count: 35,
    icon: "icon-badge",
  },*/
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          {/*} <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>*/}

          <div className="col-md-12 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-12">
                  <p>
                    {" "}
                    My name is Melvin Carter Samuel. I am a web and indie game
                    developer. I am also a full-time software developer at a
                    University in London Ontario. In a previous life, I was 50%
                    of the now-defunct{" "}
                    <a href="https://halfbot.com" target="_blank">
                      Halfbot
                    </a>
                    . Please check out my <a href="#blog"> Blog</a> or{" "}
                    <a href="#works"> Projects</a> page to see what I am
                    currently working on.
                  </p>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                {/*<div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                  </div>*/}
              </div>
            </div>
          </div>
        </div>
        {/*<div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>*/}
      </div>
    </section>
  );
}

export default About;
