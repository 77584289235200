import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import HomepageLight from "./pages/HomepageLight";
import Bloglist from "./pages/Bloglist";
import Projectlist from "./pages/Projectlist";
import BlogDetails from "./pages/BlogDetails";
import ProjectDetails from "./pages/ProjectDetails";

import "./App.scss";

function App() {

 
  return (
    
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <HomepageLight />
        </Route>
        <Route path="/projects" component={Projectlist} exact />
        <Route path="/blog" component={Bloglist} exact />
        <Route path="/blog/:id/:title" component={BlogDetails} />
        <Route path="/project/:id/:title" component={ProjectDetails} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
