import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    text: "Everything",
  },
  {
    id: 2,
    text: "gaming",
  },
  {
    id: 3,
    text: "web",
  },
];

const allData = [

  {
    id: 0,
    title: "I Don't Come In Peace.",
    category: "gaming",
    image: "images/works/idcip_work.png",
    popupLink: ["images/works/1.svg"],
    filesource: "../../projects/idcip-project-05-11-10.md",
    date: "05 November, 2010",
  },
  {
    id: 1,
    title: "The Blocks Commeth.",
    category: "gaming",
    image: "images/works/tbc_work.jpg",
    popupLink: ["images/works/1.svg"],
    filesource: "../../projects/blocks-project-17-02-11.md",
    date: "17 February, 2011",
  },
  {
    id: 2,
    title: "Super Crate Box IOS.",
    category: "gaming",
    image: "images/works/scb_work.png",
    popupLink: ["images/works/1.svg"],
    filesource: "../../projects/scb-project-05-01-12.md",
    date: "05 Jauary, 2012",
  },
  {
    id: 3,
    title: "Bullet Age.",
    category: "gaming",
    image: "images/works/bulletage_work.png",
    popupLink: ["images/works/1.svg"],
    filesource: "../../projects/bulletage-project-23-02-17.md",
    date: "02 April, 2014",
  },
  {
    id: 4,
    title: "OURA.",
    category: "web",
    image: "images/works/OURA_Logo_work.png",
    popupLink: ["images/works/1.svg"],
    filesource: "../../projects/oura-project-04-11-18.md",
    date: "05 November, 2010",
  },
  {
    id: 5,
    title: "Bomber's Run.",
    category: "gaming",
    image: "images/works/bbbanner.jpeg",
    popupLink: ["images/works/1.svg"],
    filesource: "../../projects/brun-project-23-02-17.md",
    date: "09 February, 2020",
  },

];

function Works() {
  const [getAllItems] = useState(Array.from(allData).reverse());
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 6));
  }, [getAllItems]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        console.log("they are same");
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };
  
  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Projects" />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

       { getAllItems.length > dataVisibleCount &&    
          <div className="load-more text-center mt-4">
            <button
              className="btn btn-default"
              onClick={handleLoadmore}
              disabled={noMorePost ? "disabled" : null}
            >
              {noMorePost ? (
                "No more items"
              ) : (
                <span>
                  <i className="fas fa-spinner"></i> Load more
                </span>
              )}
            </button>
          </div>
        } 
        
      
      </div>

      
    </section>
  );
}

export default Works;
