import React from "react";
import { Link } from "react-scroll";
import Logo from "../elements/Logo";
import { NavHashLink } from "react-router-hash-link";

import { Link as LinkTo } from "react-router-dom";
function Header({ light, logoSource, toggleMenu, headerToggler }) {
  const handleClasses = () => {
    let classes = "desktop-header-1 d-flex align-items-start flex-column light";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };
  const handleMobileClasses = () => {
    let classes = "mobile-header-1 light";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };
  document.body.classList.remove("dark");

  return (
    <>
      <header className={handleMobileClasses()}>
        <div className="container">
          <div className="menu-icon d-inline-flex mr-4">
            <button onClick={headerToggler}>
              <span></span>
            </button>
          </div>
        </div>
      </header>
      <header className={handleClasses()}>
        <Logo logoSource={logoSource} />
        <nav>
          <ul className="vertical-menu scrollspy">
            <li>
              <NavHashLink to={`/#home`} smooth={true}>
                <i className="icon-home"></i>Home
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={`/#works`} smooth={true}>
                <i className="icon-layers"></i>Projects
              </NavHashLink>
            </li>
            {/*<li>
              <Link
                activeClass="active"
                to="section-services"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-briefcase"></i>Services
              </Link>
            </li>*/}
            <li>
              <NavHashLink to={`/#experience`} smooth={true}>
                <i className="icon-graduation"></i>Experience
              </NavHashLink>
            </li>

            <li>
              <NavHashLink to={`/#blog`} smooth={true}>
                <i className="icon-note"></i>Blog
              </NavHashLink>
            </li>

            <li>
              <NavHashLink to={`/#about`} smooth={true}>
                <i className="icon-user-following"></i>About
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={`/#contact`} smooth={true}>
                <i className="icon-bubbles"></i>Contact
              </NavHashLink>
            </li>
          </ul>
        </nav>

        <div className="footer">
          <span className="copyright">
            &copy; {new Date().getFullYear()} Melvin Carter Samuel.
          </span>
        </div>
      </header>
    </>
  );
}

export default Header;
