import React, { useState, useEffect } from "react";
import Header from "../components/layouts/Header";
import Blog from "../components/elements/Blog";
import Pagination from "../components/elements/Pagination";

const allBlogs = [
  {
    id: 1,
    title: "Hello World.",
    image: "images/works/content-writing.png",
    filesource: "../../blogs/hello-world-17-01-15.md",
    date: "17 Jauary, 2015",
    author: "Carter",
    category: "Thoughts",
  },
  {
    id: 2,
    title: "I Failed My First Ludum Dare.",
    image: "images/works/content-writing.png",
    filesource: "../../blogs/i-failed-blog-19-01-15.md",
    date: "19 Jauary, 2015",
    author: "Carter",
    category: "Thoughts",
  },
  {
    id: 3,
    title: "Constraints",
    image: "images/works/content-writing.png",
    filesource: "../../blogs/constraints-blog-26-01-17.md",
    date: "26 Jauary, 2017",
    author: "Carter",
    category: "Game Design",
  },
  {
    id: 4,
    title: "Design Decisions",
    image: "images/works/GameplayTest.gif",
    filesource: "../../blogs/design-decisions-blog-28-01-17.md",
    date: "28 Jauary, 2017",
    author: "Carter",
    category: "Game Design",
  },
  {
    id: 5,
    title: "Bomber's Run",
    image: "images/works/Gameplay2.gif",
    filesource: "../../blogs/brun-blog-17-02-17.md",
    date: "17 February, 2017",
    author: "Carter",
    category: "Games",
  },
  {
    id: 6,
    title: "Bomber's Run Released!",
    image: "images/works/bbbanner_blog.jpeg",
    filesource: "../../blogs/brun-release-blog-23-02-17.md",
    date: "23 February, 2017",
    author: "Carter",
    category: "Games",
  },
  {
    id: 7,
    title: "OURA",
    image: "images/works/OURA_Logo_blog.png",
    filesource: "../../blogs/oura-blog-04-11-18.md",
    date: "04 November, 2018",
    author: "Carter",
    category: "Web",
  },
];

function Bloglist() {
  // document.body.classList.add("dark");
  //Uncomment the above line if you use dark version
  document.body.classList.remove("dark");

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    setPosts(allBlogs);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = Array.from(posts)
    .reverse()
    .slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="row blog-wrapper fix-spacing">
              {currentPosts.map((blogItem) => (
                <div className="col-md-4 " key={blogItem.id}>
                  <Blog blogData={blogItem} />
                </div>
              ))}
            </div>
            <div className="spacer" data-height="50"></div>
            {!(posts.length > postsPerPage) ? null : (
              <Pagination
                itemsPerPage={postsPerPage}
                totalItems={posts.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Bloglist;
